import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import trLocale from '@fullcalendar/core/locales/tr';

import {ref, onMounted, onUnmounted} from '@vue/composition-api'
import store from "@/store"
import storeModule from "@/views/meeting/meeting/store"
import router from "@/router";

export default function userCalendar() {
    const STORE_MODULE_NAME = 'store'

    const refCalendar = ref(null)
    let calendarApi = null
    const busy = ref(false)

    if (!store.hasModule(STORE_MODULE_NAME)) {
        store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    onUnmounted(() => {
        if (store.hasModule(STORE_MODULE_NAME)) {
            store.unregisterModule(STORE_MODULE_NAME)
        }
    })

    onMounted(() => {
        calendarApi = refCalendar.value.getApi()
    })

    const fetchEvents = (info, successCallback) => {
        if (!info) return
        busy.value = true
        store.dispatch('store/fetchItems').then(response => {
            const responseData = []
            response.data.data.forEach((value) => {
                responseData.push({
                    id: value.id,
                    title: value.title,
                    start: value.startDate,
                    end: value.endDate,
                    allDay: false,
                    location: value.meetingRoomName,
                    meetingStatusId: value.meetingStatusId,
                    meetingStatusName: value.meetingStatusName,
                })
            })

            busy.value = false
            successCallback(responseData)
        }).catch(() => {
            busy.value = false
        })
    }

    const grabEventDataFromEventApi = eventApi => {
        const {
            id,
            title,
            start,
            end,
            extendedProps: {calendar, guests, location, description},
            allDay,
        } = eventApi

        return {
            id,
            title,
            start,
            end,
            extendedProps: {
                calendar,
                guests,
                location,
                description,
            },
            allDay,
        }
    }

    const calendarOptions = ref({
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
            start: 'prev,next title',
            center: 'dayGridMonth,timeGridWeek,timeGridDay',
            end: 'newRecord',
        },
        events: fetchEvents,
        selectable: false,
        editable: false,
        eventResizableFromStart: true,
        dragScroll: false,
        dayMaxEvents: 5,
        navLinks: true,
        locale: trLocale,
        firstDay: 1,
        hiddenDays: [0],
        allDaySlot: false,
        slotDuration: '00:30:00',
        slotMinTime: '08:00:00',
        slotMaxTime: '18:00:00',
        slotLabelFormat: [
            {
                hour: 'numeric',
                minute: '2-digit',
            }
        ],
        dayHeaderFormat: {weekday: 'long', omitCommas: true},
        titleFormat: {year: 'numeric', month: 'long', day: 'numeric'},
        eventClassNames({event: calendarEvent}) {
            const colors = ['bg-light-primary', 'bg-light-success', 'bg-light-warning', 'bg-light-danger', 'bg-light-info'];
            const randomIndex = Math.floor(Math.random() * colors.length);
            return colors[randomIndex];
        },
        customButtons: {
            newRecord: {
                text: 'Yeni Kayıt',
                click() {
                    router.push({name: 'meeting-add'})
                },
            },
        },
        eventClick: function (info) {
            openContextMenu(info.event, info.jsEvent)
        }
    })

    const reFetchEvents = () => {
        calendarApi.refetchEvents()
    }

    const openContextMenu = (eventData, event) => {
        if (eventData.extendedProps.meetingStatusId === 'REPORT_SHARED') {
            router.push({name: 'meeting-report', params: {id: eventData.id}})
        } else {
            router.push({name: 'meeting-edit', params: {id: eventData.id}})
        }
    }

    return {
        refCalendar,
        calendarOptions,
        busy,

        reFetchEvents,
    }
}
