<template>
  <div class="pb-1">
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Overlay :busy="busy"></Overlay>

  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import useCalendar from './useCalendar'
import {BButton, BForm, BFormCheckbox, BFormCheckboxGroup, BFormGroup, BFormTextarea, BImg, BLink, BModal} from "bootstrap-vue";
import {formatTime, randomColor} from "@core/utils/filter";
import Overlay from "@/components/Overlay.vue";

export default {
  methods: {randomColor, formatTime},
  components: {
    BModal,
    BForm,
    BFormTextarea,
    BLink,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    BImg,
    BFormGroup,

    Overlay,
    FullCalendar,
  },
  setup() {
    const {
      refCalendar,
      calendarOptions,
      busy,

    } = useCalendar()

    return {
      refCalendar,
      calendarOptions,
      busy,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-context.scss';

.fc-button-group {
  margin-right: 10px;
}

.fc-newRecord-button {
  border-color: #6774AD !important;
  background-color: #6774AD !important;
}

</style>
